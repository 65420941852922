<template>
  <section class="request__nav">
    <div
      class="is-flex is-justify-content-space-between is-align-items-center is-relative"
    >
      <div class="is-flex is-align-items-center" style="cursor: pointer">
        <b-button class="toggle__button" @click="toggleShowRequestQuotes">
          <b-icon
            :icon="showRequestQuotes ? 'chevron-down' : 'chevron-right'"
            size="is-medium"
            custom-size="mdi-24px"
          />
        </b-button>
        <h5
          @click="redirectToRequestEdition"
          class="request__tittle has-one-line-ellipsis"
          style="max-width: 11rem"
        >
          {{ adaptedRequest.title }}
        </h5>
      </div>
      <b-tooltip
        :label="adaptedRequest.stage.name"
        type="is-light"
        position="is-right"
      >
        <b-tag
          class="request_stage__tag"
          :style="`background-color: ${adaptedRequest.stage.color};width: 1.25rem;`"
          @click="toggleShowStageTag"
        >
          <span class="request_stage__tag">
            {{ adaptedRequest.stage.acronym }}
          </span>
        </b-tag>
      </b-tooltip>

      <b-tag
        type="is-dark"
        style="
          position: absolute;
          top: -1.25rem;
          right: -1.5rem;
          padding: 0.25rem;
        "
      >
        New!
      </b-tag>
      <!-- <b-select
        v-else
        v-model="adaptedRequest.stage.id"
        :class="`${adaptedRequest.stage.name} request_stage__select`"
        size="is-small"
        @change="() => {}"
      >
        <option
          v-for="stage in constants.REQUEST_STAGES_ARRAY"
          :value="stage.id"
          :key="stage.id"
        >
          {{ stage.name }}
        </option>
      </b-select> -->
    </div>
    <ul v-if="showRequestQuotes" class="pl-5">
      <li
        v-for="quote in adaptedRequest.quotes"
        :key="quote.id"
        :class="`is-flex is-align-items-center is-justify-content-space-between my-2 request_item__container ${getRequestItemClass(
          quote
        )}`"
      >
        <a
          :href="getQuoteLink(quote.id)"
          class="cursor quote__link"
          style="cursor: pointer; max-width: 12rem"
        >
          <h6 class="has-one-line-ellipsis">{{ quote.title }}</h6>
        </a>
        <b-tooltip
          :label="quote.status.name"
          type="is-light"
          position="is-right"
        >
          <b-tag
            :style="`background-color: ${quote.status.color};width: 1.25rem;`"
          >
            {{ quote.status.acronym }}
          </b-tag>
        </b-tooltip>
      </li>
    </ul>
    <div
      class="request__footer is-flex is-justify-content-space-between is-align-items-center pl-5 mt-4"
    >
      <span
        v-if="showRequestQuotes && adaptedRequest.quotes.length > 1"
        @click="() => toggleShowAllRequestQuotes(!showAllRequestQuotes)"
        class="underline"
        style="cursor: pointer"
      >
        {{ showAllRequestQuotes ? "Collapse" : "Show All" }}
      </span>

      <b-navbar-item
        v-if="!isNewQuoteButtondisabled"
        tag="router-link"
        :to="{
          path: constants.PATH_INFO.BASIC_INFO.path,
          query: { type: 'quote' },
        }"
        class="has-text-weight-semi-bold is-6 grey-principal pb-0"
      >
        <span @click="onNewQuoteClick">+ New Quote</span>
      </b-navbar-item>
    </div>
  </section>
</template>

<script>
import { helper } from "@/utils/itinerary";
import { REQUEST_STAGES_ARRAY, REQUEST_STAGES } from "@/constants/itinerary";
import { PATH_INFO } from "@/router/path";
import { mapGetters } from "vuex";

export default {
  name: "RequestNav",
  data() {
    return {
      constants: {
        REQUEST_STAGES_ARRAY,
        PATH_INFO,
      },
      showRequestQuotes: true,
      showAllRequestQuotes: false,
      showStageTag: true,
    };
  },
  computed: {
    ...mapGetters({
      itinerary: "itinerary/getItinerary",
      formItinerary: "itinerary/getFormItinerary",
      request: "request/getRequest",
    }),
    adaptedRequest() {
      if (!this.request?.id) {
        return {
          title: "",
          stage: {},
          quotes: [],
        };
      }
      return {
        ...this.request,
        stage: helper.getFullRequestStage(this.request.stage.id),
        quotes: this.request.items.map((quote) => ({
          ...quote,
          status: helper.getFullQuoteStatus(quote.status.id),
          active: this.$route.query.itinerary === quote.id,
        })),
      };
    },
    isNewQuoteButtondisabled() {
      if (!this.request.id) return true;
      return [REQUEST_STAGES.WON.id, REQUEST_STAGES.LOST.id].includes(
        this.request.stage.id
      );
    },
  },
  methods: {
    getQuoteLink(quoteId) {
      const origin = window.location.origin;
      const url = `${origin}${PATH_INFO.BASIC_INFO.path}?itinerary=${quoteId}`;
      return url;
    },
    toggleShowRequestQuotes() {
      this.showRequestQuotes = !this.showRequestQuotes;
    },
    toggleShowStageTag() {
      this.showStageTag = !this.showStageTag;
    },
    toggleShowAllRequestQuotes(newValue = false) {
      this.showAllRequestQuotes = newValue;
    },
    getRequestItemClass(quote) {
      if (this.$route.path === PATH_INFO.REQUESTS_CREATION.path) return "show";
      if (quote.active) return "active";
      if (this.showAllRequestQuotes) return "show";
      return "";
    },
    resetCurrentQuote() {
      this.$store.dispatch("itinerary/resetItineraryAndRelatedData");
    },
    onNewQuoteClick() {
      this.resetCurrentQuote();
      this.toggleShowAllRequestQuotes(true);
      this.validateAutofillByRequest();
    },
    updateformItinerary(key, value) {
      this.$store.dispatch("itinerary/udpateFormItineraryByKey", {
        key,
        value,
      });
    },
    generateCode() {
      this.$store.dispatch("itinerary/generateCode");
    },
    validateAutofillByRequest() {
      if (this.request?.id && !this.itinerary?.data?.id) {
        this.updateformItinerary("client_id", this.request.client.id);
        this.updateformItinerary("contract_id", this.request.contract.id);
        this.updateformItinerary("title", {
          ...this.formItinerary[0].title,
          value: `${this.request.title}`,
        });
        this.generateCode();
      }
    },
    redirectToRequestEdition() {
      if (this.$route.path === PATH_INFO.REQUESTS_CREATION.path) return;

      this.resetCurrentQuote();

      this.$router.push({
        path: PATH_INFO.REQUESTS_CREATION.path,
        query: { request: this.request.id },
      });
    },
  },
  // mounted() {
  // window.addEventListener("click", ($event) => {
  //   if ($event.target.className === "request_stage__tag" || this.showStageTag)
  //     return;
  //   if ($event.target.closest(".request_stage__select") === null) {
  //     this.showStageTag = true;
  //   }
  // });
  // },
  // beforeDestroy() {
  //   window.removeEventListener("click", () => {});
  // },
};
</script>

<style>
.request__nav {
  width: 15rem;
}
.request__tittle {
  font-size: 0.9rem;
  font-weight: 600;
}
.toggle__button {
  background-color: transparent;
  border: none;
  box-shadow: none !important;
}
.toggle__button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.request__new__quote {
  color: #000;
}

.request_item__container {
  display: none !important;
}

.request_item__container.show,
.request_item__container.active {
  display: flex !important;
}

.request_item__container h6:hover {
  text-decoration: underline;
  cursor: pointer;
}

.request_item__container.active h6 {
  font-weight: 700 !important;
  color: #1d3b83;
}

.request__nav .control select {
  max-width: 7rem;
  border-radius: 0.5rem;
  border: none !important;
}
.request__nav .control.Negotiation select {
  background-color: #ffcf97;
}
.request__nav .control option {
  background-color: #fff !important;
  text-align: center;
}
</style>
