/* eslint-disable no-unused-vars */
import request from "../request";
import { AUTH_STORAGE_KEY } from "@/config.js";

const headers = () => {
  return {
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY)).token
    }`,
    "Cache-Control": "no-cache",
  };
};

export default {
  getQuotesByTime() {
    return request({
      method: "GET",
      url: `/tenant/reports/periodic-quotes`,
      headers: headers(),
    });
  },
  getQuotesByClients() {
    return request({
      method: "GET",
      url: `/tenant/reports/clients`,
      headers: headers(),
    });
  },
  loadUserSummary() {
    return request({
      method: "GET",
      url: `/tenant/reports/summary`,
      headers: headers(),
    });
  },
  getRequestsByStages(payload) {
    console.log("payload", payload);

    const params = [
      `client=${payload?.client ? payload?.client : ""}`,
      `from=${payload?.from ? payload?.from : ""}`,
      `to=${payload?.to ? payload?.to : ""}`,
    ].join("&");

    return request({
      method: "GET",
      url: `/tenant/reports/stages?${params}`,
      headers: headers(),
    });
  },
  loadQuotesByDestinations() {
    return request({
      method: "GET",
      url: `/tenant/reports/destinations`,
      headers: headers(),
    });
  },
};
