<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  BarController,
  LineController,
  PieController,
  DoughnutController,
  RadarController,
  PolarAreaController,
  BubbleController,
  ScatterController,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Legend,
  Tooltip,
  Title,
} from "chart.js";

import { FunnelController, TrapezoidElement } from "chartjs-chart-funnel";

// Register all required components
Chart.register(
  BarController,
  LineController,
  PieController,
  DoughnutController,
  RadarController,
  PolarAreaController,
  BubbleController,
  ScatterController,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Legend,
  Tooltip,
  Title,
  FunnelController,
  TrapezoidElement
);

export default {
  name: "ChartComponent",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
    chartType: {
      type: String,
      default: "bar", // default chart type
    },
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy(); // Cleanup any previous chart instance
      }

      this.chartInstance = new Chart(this.$refs.chartCanvas, {
        type: this.chartType,
        data: this.chartData,
        options: this.chartOptions,
      });
    },
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.renderChart();
      },
    },
    // chartOptions: {
    //   deep: true,
    //   handler() {
    //     this.renderChart();
    //   },
    // },
    chartType() {
      this.renderChart();
    },
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
}
</style>
